import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dailyPopup } from "../../redux/features/popup/popup";
import { useLocation, matchPath } from "react-router-dom";

export default function VerifyAccount() {
  const [showPopup, setShowPopup] = useState(false);
  const [active, setActive] = useState(false);
  const popup_staus = useSelector((state) => state.popup.value);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    console.log(popup_staus);

    const patterns = [
      "/onboarding/start",
      "/onboarding/start/select-level",
      "/onboarding/start/learning",
      "/learning/:onboarding_path_id",
      "/verification",
      "/profile",
      "/profile/subscription-plan",
      "/profile/settings",
      "/profile/language",
      "/verify-email",
      "/email-login",
    ];

    const match = patterns.reduce(
      (match, pattern) =>
        match ? match : matchPath(pattern, location.pathname),
      null
    );
    // console.log(match);
    if (match !== null) return;
    if (location.pathname === "/simulator" && popup_staus === true) {
      setTimeout(() => {
        setShowPopup(true); // Close the popup
      }, 10 * 60 * 1000);
      return;
    }
    // Set the interval for every 30 minutes (30 * 60 * 1000 milliseconds)

    const intervalId = setInterval(setShowPopup(true), 30 * 60 * 1000);

    // Show the first popup immediately
    setShowPopup(true);
    setTimeout(() => {
      setActive(true); // Close the popup
    }, 100);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  const handleClosePopup = () => {
    // console.log(popup_staus)
    setActive(false);
    setTimeout(() => {
      setShowPopup(false); // Close the popup
    }, 100);
  };
  return (
    <div
      className={
        showPopup
          ? "sessionClose_popup welcome_backPopup show"
          : "sessionClose_popup welcome_backPopup"
      }
      style={{ display: showPopup ? "flex" : "none" }}
      onClick={handleClosePopup}
    >
      <div
        className={
          active
            ? "sessionClose_popup_content active"
            : "sessionClose_popup_content"
        }
      >
        <div className="sessionClose_popup_text">
          <h3>Welcome back!</h3>

          <div className="task_box">
            <span>Leaving So Soon?</span>
            <h4 style={{ fontSize: "14px" }}>
              Verify Your Account Now With Our Startup Specialists
            </h4>
            <div className="read_more_btn">
              <a
                style={{ textAlign: "center" }}
                href="https://clickrdir.com/o/r97vy?lp=341"
              >
                Verify Your Account
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
