import React, { useEffect, useState } from "react";
import images from "../../../Assets/Images";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../../../helpers/Auth";
import Coin from "../../component/Coin";
import Skeleton from "../../component/Skeleton";
import DailyPopup from "../../component/DailyPopup";

export default function BuyAssets() {
  const navigate = useNavigate();
  const { http } = Auth();
  const [assets, setAssets] = useState("all");
  const [dataLoading, setDataLoading] = useState(true);
  const [showTicker, setShowTicker] = useState([]);
  const [state, setState] = useState({
    setQuery: "",
  });

  useEffect(() => {
    // let timeoutId;
    setShowTicker([]);
    // let isMounted = true;

    const fetchData = async () => {
      try {
        const res = await http.get(`/simulator-ticker-list/${assets}`);

        setShowTicker(res.data.data);
        setState({ ...state, asset_data: res.data.data });
        setDataLoading(false);
      } catch (error) {
        setDataLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [assets]);

  useEffect(() => {
    let isMounted = true;
    const fetchPostData = async () => {
      try {
        const res = await http
          .post("/simulator-ticker-list", { data: showTicker })
          .then((res) => {
            // console.log(res.data.data);
            setShowTicker(res.data.data);
            setDataLoading(false);
          })
          .catch((err) => {
            setDataLoading(false);
          });
      } catch (error) {
        if (isMounted) {
          setDataLoading(false);
          console.error(error);
        }
      }
    };

    const interval = setInterval(fetchPostData, 5000); // Poll every 10 seconds

    return () => {
      clearInterval(interval);
      isMounted = false;
    };
  }, [showTicker, assets]);
  const filterCourse = (type) => {
    setDataLoading(true);
    setAssets(type);
  };

  const handleKeyUp = (event) => {
    const searchValue = event.target.value;
    setState({ ...state, setQuery: searchValue });

    // Example: You can filter or fetch results here
    const searchResults = state.asset_data.filter((item) => {
      let x = item.name + item.ticker;
      return x.toLowerCase().includes(searchValue.toLowerCase());
    });
    setShowTicker(searchResults);
  };
  return (
    <>
    <div className="main_container">
      <div
        className="top_header back_header"
        onClick={() => navigate("/simulator")}
      >
        <div className="logo_box">
          <img
            src={images["left_ang.svg"]}
            className="img_fluid"
            alt="left_logo"
          />
        </div>
        <div className="right_site">
          <Coin />
        </div>
      </div>

      <div className="chapter_box simulator_box">
        <div className="search_stock_box">
          <img src={images["search.svg"]} alt="search" />
          <input
            type="search"
            placeholder="Search stocks, crypto"
            onKeyUp={handleKeyUp}
          />
        </div>
        <div className="stock_tabBox">
          <ul>
            <li
              className={assets === "all" ? "active" : ""}
              onClick={() => filterCourse("all")}
            >
              All
            </li>
            <li
              className={assets === "popular" ? "active" : ""}
              onClick={() => filterCourse("popular")}
            >
              Popular
            </li>
            <li
              className={assets === "stock" ? "active" : ""}
              onClick={() => filterCourse("stock")}
            >
              Stock
            </li>
            <li
              className={assets === "crypto" ? "active" : ""}
              onClick={() => filterCourse("crypto")}
            >
              Crypto
            </li>
          </ul>
        </div>

        <div className="asset_container">
          {dataLoading ? (
            <Skeleton />
          ) : (
            <ul>
              {showTicker && showTicker.length > 0 ? (
                showTicker.map((el) => {
                  return (
                    <li key={el.id}>
                      <Link
                        className=""
                        to={`/simulator/buy-assets/${el.ticker_name}`}
                      >
                        <div className="info_box">
                          <div className="img_box">
                            <img src={el.image} alt="company_logo" />
                          </div>
                          <div className="content_box">
                            <p className="name">{el.name}</p>
                            <p className="ticker">{el.ticker_name}</p>
                          </div>
                        </div>
                        <div className="costs_box">
                          <p className="cost">${el.latest_price||el.close_price}</p>
                          {
                            el.latest_price > 0?(
                              <>
                              {el.latest_price >= el.close_price ? (
                            <p className="pnl blue">+{el.pl_price}</p>
                          ) : (
                            <p className="pnl red">{el.pl_price}</p>
                          )}
                              </>
                            ):(
                              
                              <p className={el.difference>0?"pnl blue":"pnl red"}>{el.difference}</p>
                            )
                          }
                         
                        </div>
                      </Link>
                    </li>
                  );
                })
              ) : (
                <div className="not_matching">
                  <img
                    src={images["not_matchins.png"]}
                    className="img_fluid"
                    alt="not_matching"
                  />
                  <p>No matching assets</p>
                </div>
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
      <DailyPopup/>    
    </>
  );
}
