import React, { useState, useEffect } from "react";
import images from "../../Assets/Images";
import Auth from "../../helpers/Auth";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletBalance } from "../../redux/features/wallet/walletSlice";
import { dailyPopup } from "../../redux/features/popup/popup"; 

const DailyPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [active, setActive] = useState(false);

  const { user, http } = Auth();
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.wallet.balance);
  useEffect(() => {
    
    const lastShownDate = localStorage.getItem("popupLastShown");
    const today = new Date().toISOString().split("T")[0]; // Get today's date (YYYY-MM-DD)

    if (lastShownDate !== today) {
      setShowPopup(true); // Show popup if it hasn't been shown today
      setTimeout(() => {
        setActive(true); // Close the popup
      }, 100);
    }
  }, []);

  const handleClosePopup = () => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date
    localStorage.setItem("popupLastShown", today); // Save today's date to localStorage
    dispatch(dailyPopup(true))
    setActive(false);
    setTimeout(() => {
      setShowPopup(false); // Close the popup
    }, 100);
  };

  const collectCoin = () => {
    http
      .post(`/collect-daily-coin/${user.id}`)
      .then((res) => {
        console.log(res.data.data);
        dispatch(fetchWalletBalance(user.id));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* {showPopup && (
        <div style={popupStyle}>
          <div style={popupContentStyle}>
            <h2>Daily Popup</h2>
            <p>This is your daily popup message.</p>
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )} */}

      <div
        className={
          showPopup
            ? "sessionClose_popup welcome_backPopup show"
            : "sessionClose_popup welcome_backPopup"
        }
        style={{ display: showPopup ? "flex" : "none" }}
        onClick={handleClosePopup}
      >
        <div
          className={
            active
              ? "sessionClose_popup_content active"
              : "sessionClose_popup_content"
          }
        >
          <div className="sessionClose_popup_text">
            <div className="coin_box">
              <img src={images["coin.svg"]} alt="" />
              <p>${balance}</p>
            </div>
            <h3>Welcome back!</h3>
            <p>Your daily rewards are here</p>
            <div className="task_box">
              <span>Task for today</span>
              <h4>Nail it today</h4>
              <p>Invest $5,000 in the simulator</p>
            </div>
            <div className="read_more_btn">
              <button type="button" onClick={collectCoin}>
                Collect <img src={images["coin.svg"]} alt="coin" /> 1,000
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyPopup;
