import React, { useEffect, useState } from "react";
import "./Assets/css/Coursiv.css";
import "./Assets/css/finiva.css";
import "./Assets/css/ui-color.css";
import "./Assets/css/onboarding.css";
import Auth from "./helpers/Auth";
import GuestUser from "./route/GuestUser";
import AuthUser from "./route/AuthUser";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import VerifyAccount from "./templates/component/VerifyAccount";

function App() {
  const { getToken, user } = Auth();

  // console.log(getToken());
  if (!getToken()) {
    return <GuestUser />;
  } else {
    return (
      <Provider store={store}>
        <AuthUser />
        {user && user.is_lead_complete === false && <VerifyAccount />}
      </Provider>
    );
  }
}

export default App;
