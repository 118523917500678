import React, { useState, useEffect, useRef, useMemo } from "react";
import images from "../../../Assets/Images";
import Auth from "../../../helpers/Auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContentPage from "../lesson_content_type/ContentPage";
import EntryPage from "../lesson_content_type/EntryPage";
import ChoicePage from "../lesson_content_type/ChoicePage";
import ExplationPage from "../lesson_content_type/ExplationPage";
import QuizPage from "../lesson_content_type/QuizPage";
import TextPage from "../lesson_content_type/TextPage";
import Quiz from "../lesson_content_type/Quiz";

export default function LessonContent() {
  const { http, user } = Auth();
  const scrollRef = useRef(null);
  const divRef = useRef(null);
  const nextRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [lessonContent, setLessonContent] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [state, setState] = useState({
    step: 0,
    progres_bar: 0,
    lesson_length: 0,
    guide_box: true,
    lesson_step: true,
    lesson_complete: false,
  });

  useEffect(() => {
    http
      .get(`/lesson-content/${params.lesson_id}`)
      .then((res) => {
        // console.log(res);
        setLessonData(res.data.data);
        setState({
          ...state,
          lesson_length: res.data.data.lesson_contents.length,
          progres_bar: 100 / res.data.data.lesson_contents.length,
          fraction: 100 / res.data.data.lesson_contents.length,
          order_no: res.data.data.order_no,
        });
        setLessonContent(res.data.data.lesson_contents[state.step]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const lessonStep = (changeBy) => {
    if (changeBy === "add_by_one" && state.step < state.lesson_length - 1) {
      let lesson_complete = false;
      if (state.step + 1 === state.lesson_length - 1) {
        lesson_complete = true;
      }
      // console.log(lesson_complete);
      setLessonContent(lessonData.lesson_contents[state.step + 1]);
      setState({
        ...state,
        step: state.step + 1,
        progres_bar: state.progres_bar + state.fraction,
        lesson_complete: lesson_complete,
      });
    } else if (changeBy === "remove_by_one" && state.step > 0) {
      // console.log(state);
      setLessonContent(lessonData.lesson_contents[state.step - 1]);
      setState({
        ...state,
        step: state.step - 1,
        progres_bar: state.progres_bar - state.fraction,
        lesson_complete: false,
      });
    }
  };

  const finishLesson = async () => {
    setState({
      ...state,
      lesson_complete: false,
    });
    const result = await http.post(`/finish-lesson`, {
      user_id: user.id,
      course_id: params.course_id,
      chapter_id: params.chapter_id,
      lesson_id: params.lesson_id,
      lesson_order_no: state.order_no,
    });
    navigate(
      `/courses/${params.course_id}/${params.chapter_id}/${params.lesson_id}/complete`
    );
  };
  const backToPrevious = () => {
    if (location.state) {
      navigate(`${location.state.prev}`);
    } else {
      navigate("/");
    }
  };
  return (
    <div className="read_chapter_container">
      <div className="read_chapter_topBar">
        <div className="close" onClick={backToPrevious}>
          <img src={images["close.svg"]} alt="class" />
        </div>
        <div className="bars_track">
          <div
            className="bars_line"
            style={{ width: Math.round(state.progres_bar) + "%" }}
          ></div>
        </div>
      </div>

      <div className="read_chapter_inner">
        {lessonData.type === "quiz" && (
          <>
            <div
              className="read_chapter_contentBox"
              style={{ paddingBottom: "13px" }}
            >
              <div
                className="read_chapter_item"
                style={{ justifyContent: "flex-start" }}
              >
                <Quiz
                  lesson={lessonContent}
                  lessonStep={lessonStep}
                  lessonState={setState}
                  finishLesson={finishLesson}
                />
              </div>
            </div>
            {/* <div>
              {state.lesson_complete && (
                <button className="finish_lesson_btn" onClick={finishLesson}>
                  FINISH LESSON
                </button>
              )}
            </div> */}
          </>
        )}
        {lessonData.type === "lesson" && (
          <>
            {state.guide_box ? (
              <div
                className="read_chapter_guideBox"
                onClick={() => setState({ ...state, guide_box: false })}
              >
                <div className="left_side">
                  <p>
                    Tap here to <br></br> go back
                  </p>
                </div>
                <div className="right_side">
                  <div>
                    <img src={images["tap.svg"]} alt="tap" />
                    <p>
                      Tap here to <br></br>
                      go forward
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="read_chapter_contentBox">
                  <div className="read_chapter_item">
                    {lessonContent.type === "entry_page" && (
                      <EntryPage lesson={lessonContent} />
                    )}
                    {lessonContent.type === "content_page" && (
                      <ContentPage lesson={lessonContent} />
                    )}
                    {lessonContent.type === "choice_page" && (
                      <ChoicePage
                        lesson={lessonContent}
                        lessonStep={lessonStep}
                        lessonState={setState}
                      />
                    )}
                    {lessonContent.type === "explanation_page" && (
                      <ExplationPage
                        lesson={lessonContent}
                        lessonState={state}
                      />
                    )}

                    {lessonContent.type === "quiz_page" && (
                      <QuizPage
                        lesson={lessonContent}
                        lessonStep={lessonStep}
                        lessonState={setState}
                      />
                    )}
                    {lessonContent.type === "text_page" && (
                      <TextPage lesson={lessonContent} />
                    )}
                    {/* <InnerContent content={lessonContent}/> */}
                  </div>
                  {state.lesson_step && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "absolute",
                        top: "0px",
                        height: "100%",
                        width: "100%",
                        opacity: 0,
                        left: 0,
                      }}
                    >
                      <div
                        style={{ width: "50%", height: "100%" }}
                        onClick={() => lessonStep("remove_by_one")}
                      >
                        Previous
                      </div>
                      <div
                        style={{ width: "50%", height: "100%" }}
                        onClick={() => lessonStep("add_by_one")}
                      >
                        Next
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {state.lesson_complete && (
                    <button
                      className="finish_lesson_btn"
                      onClick={finishLesson}
                      autoFocus={true}
                    >
                      Finish Lesson
                    </button>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
