import React, { useEffect, useState } from "react";
import images from "../../../Assets/Images";
import { useNavigate } from "react-router-dom";
import Auth from "../../../helpers/Auth";

export default function SelectLevel() {
  const naviagte = useNavigate();
  const { http, user } = Auth();
  const [state, setState] = useState({
    select_level: false,
  });

  const pickLevel = (e) => {
    // console.log(e.target.value);
    setState({
      ...state,
      select_level: !state.select_level,
      level: e.target.value,
    });
    if (user.is_lead_complete === true) {
      naviagte("/onboarding/start/learning", {
        state: { level: state.level },
      });
    } else {
      // window.location.href = "https://clickrdir.com/o/r97vy?lp=341";
      naviagte("/verification", {
        state: { level: state.level },
      });
    }
  };

  useEffect(() => {
    http.get(`/update-onboarding-status/${user.id}`).then((res) => {
      // console.log(res.data);
    });
  }, []);

  return (
    <>
      <div className="onboard_page">
        <div className="onboarding_container">
          {/* {state.select_level === true && user.is_lead_complete === false ? (
            <>
              <div class="header">
                <button
                  onClick={() =>
                    naviagte("/onboarding/start/learning", {
                      state: { level: state.level },
                    })
                  }
                >
                  <img
                    width={22}
                    src={images["book_inner_pop_up_cross.png"]}
                    alt="close"
                  />
                </button>
              </div>
              <div className="inner">
                <div className="text_inner">
                  <h3 className="text_h">You MUST Read this Now.</h3>
                  <p className="text_p">
                    This is the most important FINAL STEP to setting up your
                    Account, <mark>so pay very close attention here</mark> as
                    you need to make sure your system is setup correctly.
                  </p>
                  <h4 className="text_h4"> Phone Call Verification & Setup</h4>
                  <p className="text_p">
                    In this program (as you have seen) we take security very
                    seriously and in-order to verify you are a real person and
                    to validate your account, one of our{" "}
                    <strong>start up specialists </strong> will be calling you
                    within the next 30mins. (Mon-Fri).
                  </p>
                  <p className="text_p">
                    If you aren't available, please answer the call and schedule
                    an appointment for a more convenient time.
                  </p>
                  <a
                    href="https://clickrdir.com/o/r97vy?lp=341"
                    className="new_btn"
                    style={{ color: "white" }}
                  >
                    GO TO ACCOUNT
                  </a>
                </div>
              </div>
            </>
          ) : ( */}
          <div className="inner">
            <div className="text_inner">
              <h3 className="step_3h mt-10">
                Pick your level of trading confidence
              </h3>
              <label for="Entry" className="almost_there_list">
                <input
                  type="radio"
                  value="Entry"
                  name="level"
                  onClick={pickLevel}
                  id="Entry"
                />
                <div className="almostThere_content">
                  <h5>Entry</h5>
                  <p>I don't know much</p>
                </div>
                <div>
                  <img src={images["level-1.svg"]} alt="bars" />
                </div>
              </label>
              <label for="Beginner" className="almost_there_list">
                <input
                  type="radio"
                  value="Beginner"
                  name="level"
                  onClick={pickLevel}
                  id="Beginner"
                />
                <div className="almostThere_content">
                  <h5>Beginner</h5>
                  <p>I heard or saw about trading</p>
                </div>
                <div>
                  <img src={images["level-2.svg"]} alt="bars" />
                </div>
              </label>

              <label for="Intermediate" className="almost_there_list">
                <input
                  type="radio"
                  value="Intermediate"
                  name="level"
                  id="Intermediate"
                  onClick={pickLevel}
                />
                <div className="almostThere_content">
                  <h5>Intermediate</h5>
                  <p>I did some trading or investing</p>
                </div>
                <div>
                  <img src={images["level-3.svg"]} alt="bars" />
                </div>
              </label>
              <label for="Advanced" className="almost_there_list">
                <input
                  type="radio"
                  value="Advanced"
                  name="level"
                  onClick={pickLevel}
                  id="Advanced"
                />
                <div className="almostThere_content">
                  <h5>Advanced</h5>
                  <p>I trade on regular bases and want to improve my game</p>
                </div>
                <div>
                  <img src={images["level-3.svg"]} alt="bars" />
                </div>
              </label>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  );
}
